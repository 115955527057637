<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? $t('cip.plat.xxljob.actuator.title.editHeadTitle') : $t('cip.plat.xxljob.actuator.title.addHeadTitle')"
      @head-update="headUpdate()"
      @head-update-cancel="headUpdate(true)"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getDetail, submit} from "@/api/xxlJob/jobActuatorList";


export default {
  name: "actuatorEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-update",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-update-cancel",
            type: "button",
            icon: "",
          }
        );
      } else if (['add'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        )
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.xxljob.actuator.field.appName'),
          prop: 'appName',
          labelWidth: 170,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.actuator.field.appName`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.actuator.field.appName'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.actuator.field.name'),
          prop: 'title',
          labelWidth: 170,
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t(`cip.plat.xxljob.actuator.field.name`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.actuator.field.name'),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.actuator.field.order'),
          prop: 'order',
          labelWidth: 170,
          span: 8,
          minRows:0,
          maxRows:1000000,
          type: 'number',
          placeholder: this.$t(`cip.plat.xxljob.actuator.field.order`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.actuator.field.order'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.actuator.field.addressType'),
          prop: 'addressType',
          span: 8,
          labelWidth: 170,
          row: true,
          type: 'radio',
          placeholder: this.$t(`cip.plat.xxljob.actuator.field.addressType`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.actuator.field.addressType'),
              trigger: "blur"
            },
          ],
          // dicData: [{
          //   label: this.$t('cip.plat.xxljob.actuator.field.automaticLogon'),
          //   value: 0
          // }, {
          //   label: this.$t('cip.plat.xxljob.actuator.field.manualEntry'),
          //   value: 1
          // }],
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=Logon_type",
          value: 0,
          control: (val) => {
            if (val == 0) {
              return {
                addressList: {
                  disabled: true,
                  rules: [
                    {
                      required: false,
                      message: "",
                    },
                  ],
                },
              }
            } else {
              return {
                addressList: {
                  disabled: false,
                  rules: [
                    {
                      required: true,
                      message: this.$t('cip.plat.xxljob.actuator.msg.addressMessage'),
                      trigger: "blur"
                    },
                  ],
                },
              }
            }
          },
        },
        {
          label: this.$t('cip.plat.xxljob.actuator.field.addressList'),
          labelWidth: 170,
          maxlength: 200,
          showWordLimit: true,
          prop: 'addressList',
          type: 'textarea',
          disabled: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t('cip.plat.xxljob.actuator.msg.addressMessage'),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {content} = res.data;
        this.dataForm = content;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading();
          submit({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headUpdate(cancel = false) {
      console.log("this.$refs.formLayout.$refs.form", this.$refs.formLayout.$refs.form.form)
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading();
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
